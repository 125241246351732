import { searchTable, getManageListResponseObj, getSiteListByAccountId, getAllPrograms,getAllUsers } from '@/services/userService/users-api';
import store from '@/store';
import {
  getModule,
  Module,
  VuexModule,
  MutationAction,
  Action,
  Mutation
} from 'vuex-module-decorators';
import APP_CONST from '@/constants/AppConst';
import { roles, ICreateUser, IUserRoles } from '@/Model/programModel';
import { AddSiteDTO } from '@/services/program/adminService';
import { addPartnerUser, editPartnerUser, getPrimaryAccountAdmin } from '@/services/create-user/createUserService';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { ConditionObject, ValidateObject } from '@/Model/model';

@Module({
  namespaced: true,
  name: 'manageListStore',
  store,
  dynamic: true
})

class ManageListModule extends VuexModule {
  managedata: any = {};
  usersStatus: any = {};
  searchdata: any = {};
  userInfo = {} as { userDetails: ICreateUser; userRole: IUserRoles[]; accountRoleToMap: { id: number; name: string }; isPrimaryAccountAdmin: boolean; isAccountAdmin: boolean };
  allActivePrograms: any = [];
  allActiveProgramsObject: any = [];
  listOfUsers:any = [];
  usersCount:number=0;
  adminData : any = {};
  usersPayload: any = APP_CONST.MANAGE_PAYLOAD;
  public usersResizableArray:any[] = [];
  public usersResizeObj:any = [];
  public usersResizeData: any = [];
  public authKey: string = '';
  public multiProgramDropdown = {label: 'Program', id: 0, value: 'Select', required: true, disable: false, search : true, placeholder : 'Search', dropdownList: []  , checked:false, isAllSelect:false, selectDropdownCount:true};
  public singleProgramDropdown = {label: 'Program', id: 0, value: 'Select',firstSelectLabel: 'Select', required: true, disable: false, search : true, placeholder : 'Search', showSelectLabel: true, dropdownList: []  as {id: number; value: string}[]};
  public multiSiteDropdown = {label: 'Site/Session', id: 0, value: 'Select', required: true, disable: false, search : true, placeholder : 'Search', dropdownList: []  , checked:false, isAllSelect:false, selectDropdownCount:true};

  public searchUser : any = {
    firstName:'',
    lastName:'',
    systemRole: '',
    invitationStatus: '',
    invitationDate:'',
    programName:'',
    lastLogin:'',
    siteName:'',
    position:'',
    email:'',
    phone:'',
    revokeDate:'',
    isExactMatch: false
  };
  public allUsersSearchObj:any = [];
  public password: string = '';
  public allUsersSortObj:any = [];

  public isPasswordValid: boolean = false;
  public rules: Array<ConditionObject> = [
    { message: APP_CONST.TEN_CHARACTER_MSG, regex: /^(?=.*[A-Za-z0-9_@]).{10,15}$/ },
    { message: APP_CONST.CAPITAL_MSG, regex: /[A-Z]+/ },
    { message: APP_CONST.LOWERCASE_MSG, regex: /[a-z]+/ },
    { message: APP_CONST.SPECIAL_CHAR_MSG, regex: /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/ },
    { message: APP_CONST.ONE_NUMBER, regex: /[0-9]+/ }
  ];

  get passwordValidityCheck(): boolean {
    return this.isPasswordValid;
  }

  get getRules(): Array<ConditionObject> {
    return this.rules;
  }

  get getPassword(): string {
    return this.password;
  }

  get usersTabPayload(){
    return this.usersPayload;
  }

  get manageList() {
    return this.managedata;
  }

  get countOfUsersList(){
    return this.usersCount;
  }

  get partnerUserDetails() {
    return this.userInfo;
  }

  get activePrograms() {
    return this.allActivePrograms;
  }

  get activeProgramsObject() {
    return this.allActiveProgramsObject;
  }

  get totalUsersList(){
    return this.listOfUsers;
  }

  get accountAdminDetails(){
    return this.adminData;
  }

  get usersResizeArray() {
    return this.usersResizeData;
  }

  get searchUsers(){
    return this.searchUser;
  }

  get allUserSearchObj(){
    return this.allUsersSearchObj;
  }

  get getmultiProgramDropdown(){
    return this.multiProgramDropdown;
  }

  get getsingleProgramDropdown(){
    return this.singleProgramDropdown;
  }

  get getmultiSiteDropdown(){
    return this.multiSiteDropdown;
  }
    

    @Mutation
  mutatemultiProgramDropdown(multiProgramDropdown:any){
    this.multiProgramDropdown = multiProgramDropdown;
  }

    @Mutation
    mutatesingleProgramDropdown(singleProgramDropdown:any){
      this.singleProgramDropdown = singleProgramDropdown;
    }

    @Mutation
    mutatemultiSiteDropdown(multiSiteDropdown:any){
      this.multiSiteDropdown = multiSiteDropdown;
    }


  @Mutation
    updateProgramList(programList: []) {
      this.allActivePrograms = programList;
    }

  @Mutation
  updateProgramsObjectList(activePrograms: any) {
    this.allActiveProgramsObject = activePrograms;
  }

  @Mutation
  updateManageData(manageData: any) {
    this.managedata = manageData;
  }

  @Action({ commit: 'updateManageData' })
  async getManageList(payload: any) {
    const manageResponseData = async (payload: any): Promise<Object> => {
      const response = await getManageListResponseObj(payload);
      const userslist = response.data.results || [];
      for (const item of userslist) {
        const roles = item.role.filter((role: any) => {
          if (role.id > APP_CONST.ACCOUNT_ADMIN_ROLE_ID) {
            return this.activePrograms && this.activePrograms.includes(role.programId);
          }
          else {
            return role;
          }
        }).map((el: { id: number }) => el.id);
        const programId = [...new Set(item.role.filter((itm: any) => itm.id !== 4).map((el: { programId: number }) => {
          if (el.programId && this.activePrograms && this.activePrograms.includes(el.programId)) {
            return el.programId;
          }
        }))];
        const highestRole = Math.min(...roles);
        const programRoles = roles.filter((role: number, idx: number) => roles.indexOf(role) === idx || (role === APP_CONST.PROGRAM_ADMIN_ROLE_ID));
        item.role = item.role.find((el: { id: number }) => el.id === highestRole);
        item.role = {
          ...item.role, roleCount: programRoles.length > 1
            ? `${(programRoles.length - 1)}`.padStart(2, '+')
            : '',
          programCount: programId.length > 1
            ? `${(programId.length - 1)}`.padStart(2, '+')
            : ''
        };
        if (item.programId) {
          if (!this.activePrograms.includes(item.programId)) {
            item.programId = item.role && item.role.programId;
            const filteredProgram = this.activeProgramsObject && this.activeProgramsObject.filter((program: any) => {
              if (program.programId == item.programId) {
                return program;
              }
            });
            item.programName = filteredProgram[0] && filteredProgram[0].programName;
          }
        }
        else{
          item.programName= '';
        }
      }
      return response;
    };
    return manageResponseData(payload);
  }

  @MutationAction
  searchAccountList(search_input: string) {
    return searchTable(search_input).then((response) => {
      return { searchdata: response };
    }, (error) => {
      return { searchdata: error };
    });
  }

  @Mutation
  setUserToEdit(data: { userDetails: ICreateUser; userRole: IUserRoles[]; accountRoleToMap: { id: number; name: string }; isPrimaryAccountAdmin: boolean; isAccountAdmin: boolean }) {
    this.userInfo = data;
  }

  @Action({ commit: 'setUserToEdit' })
  async partnerUserData(userData: ICreateUser): Promise<Object> {
    const resultNow = async function getUserToEdit(userDetails: ICreateUser): Promise<Object> {
      const userRoles = [...userDetails.userRoles];
      const accountRoleIdx = userRoles.findIndex((el) => el.roleId === APP_CONST.ACCOUNT_ADMIN_ROLE_ID);
      const accountUserRole = userRoles.filter((el: { roleId: number }) => el.roleId !== APP_CONST.ACCOUNT_ADMIN_ROLE_ID);
      const lowestRole = Math.max(...accountUserRole.map((userDt: roles) => userDt.roleId));
      const accountIdx = userRoles.findIndex((el) => el.accountId);
      const sites = [];
      let groupedListByProgramId: any;
      let groupedClasssesByProgramId: any;
      if ([6, 7, 9].includes(lowestRole)) {
        const accountId = userRoles[accountIdx].accountId;
        let siteArr = [];
        const promise = getSiteListByAccountId(accountId);
        const result = await promise;
        if (result.status === 200) {
          siteArr = result.data;
          const key = 'programId';
          groupedListByProgramId = siteArr.reverse().map((obj: any) => ({ ...obj, checked: false })).reduce((rv: any, x: any) => {
            if (x.siteId && (x.siteStatus === 1)) {
              (rv[x[key]] = rv[x[key]] || []).push(x);
            }
            return rv;
          }, {});
        }
      }


      const userRole = accountUserRole.reduce((arr: any, item) => {
        const idx = arr.findIndex((val: { programId: number }) => val.programId === item.programId);
        const siteOrStaff = [6, 7, 9].includes(item.roleId);
        const siteListToAdd = siteOrStaff
          ? groupedListByProgramId[item.programId]
          : [];
        siteListToAdd && siteListToAdd.forEach((site: AddSiteDTO) => {
          site['userStatus'] = site.userStatus
            ? site.userStatus
            : 1;
        });
        if (siteListToAdd && siteListToAdd.length) {
          const index = siteListToAdd.findIndex((el: { siteId: number }) => el.siteId === item.siteId);
          if (index >= 0) {
            siteListToAdd[index]['checked'] = true;
            siteListToAdd[index]['userStatus'] = item.status;
          }
        }
        if (idx < 0) {
          const programRoleToPush = {
            id: item.id, roleId: item.roleId, accountId: item.accountId, programId: item.programId,
            siteList: siteListToAdd, sites: siteOrStaff
              ? [{
                id: item.id, siteName: item.siteName, siteId: item.siteId, checked: true, status: item.status,
                siteDrop: false, checkAllClass: false, classDrop: false, classList: [], classes: item.roleId > 6
                  ? item.userRoleClassrooms
                  : [], siteSelectionError: false
              }]
              : [],
            siteId: 0, programRole: item.programRole || '', inactiveDate: item.inactiveDate || '', status: item.status,
            roleName: APP_CONST.USER_TYPE_LABEL[item.roleId - 1], progName: item.programName || 'Select',
            isPrimaryAccountAdmin: item.isPrimaryAccountAdmin, roleDrop: false, progDrop: false, siteDrop: false, accordOpen: false
          };
          return arr.concat([programRoleToPush]);
        }
        else {
          arr[idx].sites.push({
            id: item.id, siteName: item.siteName, siteId: item.siteId, checked: true, status: item.status,
            siteDrop: false, checkAllClass: false, classDrop: false, classList: [], classes: item.roleId > 6
              ? item.userRoleClassrooms
              : [], siteSelectionError: false
          });
          return arr;
        }
      }, [] as IUserRoles[]) as IUserRoles[];

      userRole.forEach((el) => {
        if ([6, 7, 9].includes(el.roleId)) {
          const inactiveSites = el.sites.filter(site => site.status === 2);
          if (inactiveSites.length === el.sites.length) {
            el.status = 2;
          }
          else {
            el.status = 1;
          }
        }
        if (el.siteList && el.siteList.length && (el.siteList.length === el.sites.length)) {
          el.checkAllSite = true;
        }
        else {
          el.checkAllSite = false;
        }
      });


      let accountRoleToMap, isPrimaryAccountAdmin, isAccountAdmin;
      if (accountIdx >= 0) {
        accountRoleToMap = { id: userRoles[accountIdx].accountId, name: userRoles[accountIdx].accountName };
        userDetails.userRoles = userRoles.filter((el) => el.roleId === APP_CONST.ACCOUNT_ADMIN_ROLE_ID);
        isAccountAdmin = accountRoleIdx >= 0
          ? true
          : false;
        isPrimaryAccountAdmin = (accountRoleIdx >= 0 && userRoles[accountRoleIdx].isPrimaryAccountAdmin) || false;
      }
      return { userDetails, userRole, accountRoleToMap, isPrimaryAccountAdmin, isAccountAdmin };
    };
    return resultNow(userData);
  }

  @Action({ commit: 'updateProgramList' })
  public async getAllPrograms() {
    const promise = getAllPrograms();
    const result = await promise;
    const mappedPrograms = result.data && result.data.map((program: { id: number; status: string; name: string }) => {
      return { id: program.id, status: program.status, name: program.name };
    });
    const filteredPrograms: any = [];
    const activeProgramWithName: any = [];
    mappedPrograms && mappedPrograms.forEach((program: { id: number; status: number; name: string }) => {
      if (program.status == APP_CONST.ONE) {
        filteredPrograms.push(program.id);
        activeProgramWithName.push({ programId: program.id, programName: program.name });
      }
    });
    this.updateProgramsObjectList(activeProgramWithName);
    return filteredPrograms;
  }
  groupListByKey(list: any, key: number | string) {
    return list.reduce((rv: any, x: any) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }



  @Mutation
  public updatedUserList(data:any) {
    this.listOfUsers = data.data.results;
    this.usersStatus = data.status;
    this.usersCount = data.data.count;
  }

  @Mutation
  public mutateAccountAdminDetails(data: any){
    this.adminData = data;
  }

  @Action({ commit: 'updatedUserList'})
  public getAllUsersList(payload:any):Promise<any>{
    const resultNew = async function getListOfAllUsers():Promise<any>{
      const promise = getAllUsers(payload);
      const result = await promise;
      return result ;
    };
    return resultNew();
  }

@Action
  public addUser(payload: ICreateUser) {
    async function addUserDetails(payload: ICreateUser): Promise<Object> {
      const promise = addPartnerUser(payload);
      const result = await promise; 
      return result as object;
    }
    return addUserDetails(payload);
  }
  
@Action
public editUser(payload: ICreateUser) {
  async function editUserDetails(payload: ICreateUser): Promise<Object> {
    const promise = editPartnerUser(payload);
    const result = await promise; 
    return result as object;
  }
  return editUserDetails(payload);
}

@Action
public getPrimaryAccountAdminDetails(payload:number):Promise<any>{
  async function getPrimaryAdmin(payload: number): Promise<Object> {
    const promise = getPrimaryAccountAdmin(payload);
    const result = await promise; 
    return result as object;
  }
  return getPrimaryAdmin(payload);
}

@Mutation
mutateResizeData(obj: { column: any; maxWidth: string; minWidth: string }) {
  const accountId: any = APP_UTILITIES.getCookie('accountId');
  this.authKey = APP_UTILITIES.authTokenKeyToManage()
    ? APP_UTILITIES.authTokenKeyToManage()
    : '';
  if (!this.usersResizeObj.hasOwnProperty([accountId])) {
    this.usersResizableArray = [];
  }
  else if (this.usersResizeObj.hasOwnProperty([accountId])){
    this.usersResizableArray = JSON.parse(JSON.stringify(this.usersResizeObj[accountId]));
  }

  const keyIndex = this.usersResizableArray.findIndex((e: { id: any }) => e.id == obj.column.id);
  if (keyIndex > -1) {
    this.usersResizableArray[keyIndex] = { id : obj.column.id, maxWidth : obj.maxWidth,minWidth : obj.minWidth };
  }
  else {
    this.usersResizableArray.push({ id: obj.column.id, maxWidth : obj.maxWidth,minWidth : obj.minWidth });
  }
  
  if (!Object.keys(this.usersResizeObj).length) {
    this.usersResizeObj = {
      [accountId]: JSON.parse(JSON.stringify(this.usersResizableArray))
    };
  }
  if (this.usersResizeObj.hasOwnProperty([accountId])) {
    this.usersResizeObj[accountId] = JSON.parse(JSON.stringify(this.usersResizableArray));
  }
  else {
    const newObj = {
      [accountId]: JSON.parse(JSON.stringify(this.usersResizableArray))
    };
    Object.assign(this.usersResizeObj, JSON.parse(JSON.stringify(newObj)));
  }
  APP_UTILITIES.setFilteredColumns(`${this.authKey}-usersResizeData`, this.usersResizeObj);
}

@Mutation
mutateUsersPayload(val: any){
  this.usersPayload = val;

  this.authKey = APP_UTILITIES.authTokenKeyToManage()
    ? APP_UTILITIES.authTokenKeyToManage()
    : '';
  this.usersResizeData = [];
  const accountId: any = APP_UTILITIES.getCookie('accountId');
  if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-usersResizeData`)) {
    let usersResizeObject: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-usersResizeData`);
    usersResizeObject = JSON.parse(usersResizeObject);
    this.usersResizeObj = usersResizeObject;
    if (usersResizeObject.hasOwnProperty([accountId])) {
      this.usersResizeData=usersResizeObject[accountId];
    }
  }
  if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-AllUsersSearchObj`)) {
    let allUsrSearch: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-AllUsersSearchObj`);
    allUsrSearch = JSON.parse(allUsrSearch);
    this.allUsersSearchObj = allUsrSearch;

    if (allUsrSearch.hasOwnProperty([accountId])) {
      this.searchUser = allUsrSearch[accountId];
      const searchKeyResult = Object.keys(allUsrSearch[accountId]).find(keyName=> (allUsrSearch[accountId][keyName] != '' && allUsrSearch[accountId][keyName] != null));
      this.usersPayload['searchField'] = searchKeyResult
        ? searchKeyResult
        : '';
      this.usersPayload['search'] =  searchKeyResult && allUsrSearch[accountId] && allUsrSearch[accountId][searchKeyResult]
        ? allUsrSearch[accountId][searchKeyResult]
        : '';
      this.usersPayload['isExactMatch'] =  searchKeyResult && allUsrSearch[accountId] && allUsrSearch[accountId].isExactMatch || false;
    }
  }
  if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-UsersSortPayload`)) {
    let usrPayload: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-UsersSortPayload`);
    usrPayload = JSON.parse(usrPayload);
    this.allUsersSortObj = usrPayload;
    if (usrPayload.hasOwnProperty([accountId])) {
      this.usersPayload['sortField'] = usrPayload[accountId].sortField
        ? usrPayload[accountId].sortField
        : '';
      this.usersPayload['sortDir'] = usrPayload[accountId].sortDir
        ? usrPayload[accountId].sortDir
        : '';
    }
  }
}

@Mutation
mutateSortPayload(data:any){
  this.authKey = APP_UTILITIES.authTokenKeyToManage()
    ? APP_UTILITIES.authTokenKeyToManage()
    : '';
  const accountId: any = APP_UTILITIES.getCookie('accountId');
    
  const payload = { ...this.allUsersSortObj, [accountId]: JSON.parse(JSON.stringify(data))};

  APP_UTILITIES.setFilteredColumns(`${this.authKey}-UsersSortPayload`, payload);
      
}

@Mutation
mutateCurrentObj(data:any){
  this.searchUser = data;
}

@Mutation
mutateUsersSearchObj(data: any) {
  this.authKey = APP_UTILITIES.authTokenKeyToManage()
    ? APP_UTILITIES.authTokenKeyToManage()
    : '';
  const accountId: any = APP_UTILITIES.getCookie('accountId');
  this.searchUser = data;
  this.allUsersSearchObj = { ...this.allUsersSearchObj, [accountId]: JSON.parse(JSON.stringify(data))};

  APP_UTILITIES.setFilteredColumns(`${this.authKey}-AllUsersSearchObj`, this.allUsersSearchObj);
}


  @Mutation
public setPasswordMutate(payload: string): void {
  this.password = payload;
}

  @Action({ commit: 'setPasswordMutate' })
  public setPassword(payload: string): string {
    return payload;
  }

  @Mutation
  public mutatePasswordValid(payload: ValidateObject) {
    this.isPasswordValid = payload.valid;
  }
  @Action({ commit: 'mutatePasswordValid' })
  public setIsPasswordValid(obj: ValidateObject): ValidateObject {
    return obj;
  }

}
export default getModule(ManageListModule);
